import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";

export const SidebarWrapper = ({
  children,
  className,
  ...rest
}: ComponentPropsWithoutRef<"div">) => (
  <aside
    aria-label={t("left_sidebar")}
    className={classnames("standard-layout-v2__sidebar", className)}
    data-testid="standard-layout-v2-sidebar"
    {...rest}
  >
    <nav>{children}</nav>
  </aside>
);
