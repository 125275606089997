import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { CommunityLogo } from "@circle-react/components/shared/CommunityLogo";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import "./styles.scss";

export const FullScreenTopbar = ({
  onClose,
  actions,
  buttonPosition = "end",
  showCommunityIcon = true,
}) => {
  const { currentCommunity } = usePunditUserContext();

  return (
    <div
      className={classnames("react-layout-fullscreen-topbar", {
        "react-layout-fullscreen-topbar--reverse": buttonPosition === "start",
      })}
    >
      {showCommunityIcon && currentCommunity && (
        <div className="react-layout-fullscreen-topbar__community-icon">
          <CommunityLogo
            brand_color={currentCommunity.prefs.brand_color}
            brand_text_color={currentCommunity.prefs.brand_text_color}
            {...currentCommunity}
          />
        </div>
      )}
      <div className="react-layout-fullscreen-topbar__actions">{actions}</div>
      <button type="submit" className="btn-close" onClick={onClose}>
        <Icon type="close" />
        <span className="sr-only">{t("full_screen_layout.close_panel")}</span>
      </button>
    </div>
  );
};

FullScreenTopbar.propTypes = {
  onClose: PropTypes.func,
  actions: PropTypes.node,
  buttonPosition: PropTypes.oneOf(["start", "end"]),
  showCommunityIcon: PropTypes.bool,
};
