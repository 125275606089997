import { useState } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { EditSpaceGroupForm } from "@circle-react/components/SpaceGroups/EditFormV2";
import { useEditSpaceGroupMutation } from "@circle-react/components/SpaceGroups/EditFormV2/hooks/useEditSpaceGroupMutation";
import { DeleteSpaceGroupModal } from "@circle-react/components/SpaceGroups/Header/DeleteSpaceGroupModal";
import { useSpaceGroupResource } from "@circle-react/contexts";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Tab } from "@circle-react-uikit/TabV2";
import { SpaceGroupMembers } from "./SpaceGroupMembers";

const formId = "edit-space-group-form";

export interface EditSpaceGroupModalProps {
  spaceGroup: SpaceGroup;
  tab?: "Details" | "Members";
}

const availableTabs: ["Details", "Members"] = ["Details", "Members"];

const EditSpaceGroupModalComponent = ({
  spaceGroup: _spaceGroup,
  tab = availableTabs[0],
}: EditSpaceGroupModalProps) => {
  const [currentTab, setCurrentTab] = useState(tab);
  const [shouldShowConfirmDeleteModal, toggleShouldShowConfirmDeleteModal] =
    useBoolean(false);
  const spaceGroupId = _spaceGroup?.id;
  const {
    data: spaceGroupDetails = {},
    isLoading: isLoadingSpaceGroupResource,
  } = useSpaceGroupResource(spaceGroupId);
  const isSmScreen = useSmScreenMediaQuery();

  const spaceGroup = { ..._spaceGroup, ...spaceGroupDetails };
  const drawer = useModal();
  const isDetails = currentTab === "Details";
  const isMembers = currentTab === "Members";
  const activeSpaceGroupMembersCount = spaceGroup?.active_members_count;

  const { mutate: EditSpaceGroupMutation, isLoading: isEditing } =
    useEditSpaceGroupMutation({
      spaceGroup,
      onSuccess: drawer.hide,
    });

  return (
    <>
      <Modal
        isOpen={drawer.visible}
        onClose={drawer.hide}
        contentPortalId="edit-space-group-content-portal"
        title={t("space_groups.form.edit.title")}
      >
        <Modal.Overlay />
        <Modal.Content size="xl">
          <Modal.Header>
            <Modal.Title size="md">
              {t("space_groups.form.edit.title")}
            </Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={drawer.hide} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body
            id="scrollable-content"
            className={classNames({
              "px-0 py-0": isMembers,
            })}
          >
            <Tab.Group
              selectedIndex={availableTabs.indexOf(currentTab)}
              onChange={tabIndex => setCurrentTab(availableTabs[tabIndex])}
            >
              <Tab.List>
                <Tab>{t("space_groups.details")}</Tab>
                <Tab>
                  <div className="flex items-center gap-2">
                    {t("space_groups.members")}{" "}
                    <BadgeV2
                      square
                      inverted
                      label={String(activeSpaceGroupMembersCount)}
                    />
                  </div>
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4">
                <Tab.Panel>
                  <EditSpaceGroupForm
                    formId={formId}
                    onSubmit={EditSpaceGroupMutation}
                    defaultValues={spaceGroup}
                    loading={isLoadingSpaceGroupResource}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <SpaceGroupMembers spaceGroup={spaceGroup} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Modal.Body>
          {isDetails && (
            <Modal.Footer className="flex w-full justify-between gap-4 md:gap-0">
              <Button
                type="button"
                variant="danger-outline"
                className="hidden md:inline-block"
                onClick={toggleShouldShowConfirmDeleteModal}
              >
                {t("space_groups.delete_space_group")}
              </Button>
              <Button
                type="button"
                full
                variant="secondary"
                className="inline-block text-center md:hidden"
                onClick={() => {
                  void drawer.hide();
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                full={isSmScreen}
                type="submit"
                variant="circle"
                form={formId}
                disabled={isEditing}
              >
                {isEditing
                  ? t("space_groups.form.edit.submitting")
                  : t("save_changes")}
              </Button>
            </Modal.Footer>
          )}
        </Modal.Content>
      </Modal>
      <DeleteSpaceGroupModal
        spaceGroupId={spaceGroup.id}
        show={shouldShowConfirmDeleteModal}
        onClose={toggleShouldShowConfirmDeleteModal}
        onSuccess={drawer.hide}
      />
    </>
  );
};

const EditSpaceGroupModal = ModalManager.create(
  (props: EditSpaceGroupModalProps) => (
    <EditSpaceGroupModalComponent {...props} />
  ),
);

export const useEditSpaceGroupModal = () => useModal(EditSpaceGroupModal);
