import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { SpotlightSearchInput } from "@/react/components/search/SpotlightSearch";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { FilterContextProvider } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { SortContextProvider } from "@circle-react/components/Modals/SearchResultsModal/SortContext";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Modal } from "@circle-react-uikit/ModalV2";

const SpotlightSearchComponent = () => {
  const drawer = useModal();
  const { isV3Enabled } = useIsV3();

  return (
    <Modal
      title={t("search_v2.search_community_quick")}
      isOpen={drawer.visible}
      onClose={drawer.remove}
    >
      {isV3Enabled && <Modal.Overlay />}
      <Modal.Content
        position={isV3Enabled ? "top" : "default"}
        className={classNames(
          "border-primary w-full rounded-[0px] sm:rounded-lg sm:border md:w-[620px]",
          {
            "md:!mt-2": !isV3Enabled,
          },
        )}
        size="xl"
      >
        <FilterContextProvider>
          <SortContextProvider>
            <SpotlightSearchInput onClose={drawer.hide} />
          </SortContextProvider>
        </FilterContextProvider>
      </Modal.Content>
    </Modal>
  );
};

const SpotlightSearch = ModalManager.create(() => <SpotlightSearchComponent />);

export const useSpotlightSearch = () => useModal(SpotlightSearch);
