import type { FocusEvent, MouseEvent } from "react";
import { t } from "@/i18n-js/instance";
import type {
  AdvancedSearchResultMember,
  AdvancedSearchResultSpace,
} from "@/react/components/search/types";
import { useThemeContext } from "@/react/providers";
import { LinkOrButton } from "@circle-react/components/search/SearchQuickItem/LinkOrButton";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

interface SearchQuickItemProps {
  isSelected: boolean;
  onClick: () => void;
  onMouseOver: (event: MouseEvent) => void;
  onFocus: (event: FocusEvent) => void;
  index: number;
  result: AdvancedSearchResultMember | AdvancedSearchResultSpace;
  link?: string | null;
}

export const SearchQuickItem = ({
  isSelected,
  onClick,
  onMouseOver,
  onFocus,
  index,
  result,
  link,
}: SearchQuickItemProps) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const isCommunityAdmin = Boolean(currentCommunityMember?.is_admin);
  const isMemberType = result.type === "community_member";
  const isSpaceType = result.type === "space";
  const isActiveMember = isMemberType && result.profile_confirmed_at;

  const shouldShowEmail =
    isMemberType &&
    (isCommunityAdmin ||
      (!currentCommunity?.hide_emails_on_member_profiles &&
        !result.hide_email));

  const emojiObj = isSpaceType
    ? {
        emoji: result.emoji,
        customEmojiUrl: result.custom_emoji_url,
        customEmojiDarkUrl: result.custom_emoji_dark_url,
      }
    : {};
  const { currentAppearance } = useThemeContext();
  const shouldDisplayTypeBadge = isSpaceType || isMemberType;
  const canHover = useAnyHoverMediaQuery();

  const badgeTextScope = isSpaceType
    ? "space"
    : isActiveMember
      ? "community_member"
      : "invited_member";

  return (
    <li
      aria-current={isSelected}
      aria-posinset={index}
      className={classNames(
        "focus:bg-tertiary group flex w-full cursor-pointer list-none items-center rounded px-4",
        {
          "bg-tertiary": isSelected,
        },
        shouldShowEmail ? "py-1.5" : "py-2.5",
      )}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
    >
      <LinkOrButton onClick={onClick} onKeyDown={onClick} link={link}>
        {isMemberType ? (
          <UserImage
            src={result.avatar_url ?? ""}
            name={result.name}
            size="6"
          />
        ) : (
          <div className="min-h-[1.5rem] min-w-[1.5rem]">
            <EmojiRenderer
              className="flex !h-6 !w-6 items-center justify-center align-top"
              emoji={emojiObj.emoji}
              customEmojiUrl={emojiObj.customEmojiUrl}
              customEmojiDarkUrl={emojiObj.customEmojiDarkUrl}
              appearance={currentAppearance}
            />
          </div>
        )}
        <div
          className={classNames("flex flex-col", {
            "pt-1.5": shouldShowEmail,
          })}
        >
          <Typography.LabelSm as="div" truncate>
            <SanitizeHTMLString content={result.highlighted_name} />
          </Typography.LabelSm>
          {shouldShowEmail && (
            <div className="-mt-1">
              <Typography.LabelSm as="span" truncate>
                <span className="text-light leading-none">{result.email}</span>
              </Typography.LabelSm>
            </div>
          )}
        </div>
        <span
          className={classNames("ml-auto hidden group-focus:block", {
            "!block": isSelected || !canHover,
          })}
        >
          {shouldDisplayTypeBadge && (
            <Typography.LabelXs color="text-default">
              {t(["search_v2.types", badgeTextScope])}
            </Typography.LabelXs>
          )}
        </span>
      </LinkOrButton>
    </li>
  );
};
