import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useProfileFieldsList } from "@circle-react/components/shared/MemberFilters/ProfileFieldFilter/useProfileFieldsList";
import { FormTextSelect } from "@circle-react-uikit/Form/FormTextSelect";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "settings.workflows.form";

export const ProfileFieldSelector = () => {
  const { register } = useFormContext();

  const {
    getOptions: {
      data: customProfileFields,
      isLoading: isLoadingProfileFields,
    },
  } = useProfileFieldsList();

  if (isLoadingProfileFields) {
    return <Loader center />;
  }

  const options = customProfileFields?.map(({ id, label }) => ({
    value: id,
    label: label,
  }));

  return (
    <>
      <input
        type="hidden"
        className="hidden"
        {...register("trigger.resource_type")}
      />
      <FormTextSelect
        name="trigger.resource_id"
        label={
          <Typography.LabelSm weight="semibold">
            {t([i18nRoot, "choose_profile_field"])}
          </Typography.LabelSm>
        }
        options={options}
        rules={{
          required: t("settings.workflows.form.errors.required"),
        }}
        emptyMessage={t(
          "settings.workflows.form.errors.profile_fields.not_found",
        )}
      />
    </>
  );
};
