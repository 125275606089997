import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { useIsEitherOptInOrPreviewBarVisible } from "@circle-react/components/NewVersionOptIn/useIsEitherOptInOrPreviewBarVisible";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useSettingsHeader } from "@circle-react-shared/uikit/HeaderV3/useSettingsHeader";
import { getHeightV2Styles, getHeightV3Styles } from "./utils";

export interface ContentWrapperProps extends ComponentPropsWithoutRef<"div"> {
  isBgSecondary?: boolean;
  isBgPrimary?: boolean;
}

export const ContentWrapper = ({
  children,
  isBgSecondary = false,
  isBgPrimary = false,
  className,
  ...rest
}: ContentWrapperProps) => {
  const { shouldShowTrialBanner } = useTrialBanner();
  const isLgScreen = useTailwindLgScreenMediaQuery();
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();
  const { isV3Enabled } = useIsV3();
  const isEitherOptInOrPreviewBarVisible =
    useIsEitherOptInOrPreviewBarVisible();
  const { isReactFlowWorkflowsRoute } = useSettingsHeader();

  const heightStyles = isV3Enabled
    ? getHeightV3Styles(
        shouldShowTrialBanner,
        isLgScreen,
        isMasqueradingEnabledAndActive,
        isEitherOptInOrPreviewBarVisible,
        isReactFlowWorkflowsRoute,
      )
    : getHeightV2Styles(
        shouldShowTrialBanner,
        isLgScreen,
        isMasqueradingEnabledAndActive,
        isEitherOptInOrPreviewBarVisible,
        isReactFlowWorkflowsRoute,
      );

  return (
    <div
      className={classnames(
        "standard-layout-v2__content-wrapper",
        {
          "!bg-secondary": isBgSecondary,
          "bg-primary": isBgPrimary,
        },
        heightStyles,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
