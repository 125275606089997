import { Route, Switch } from "react-router-dom";
import { BotChatRoom } from "./BotChatRoom";
import { EmptyState } from "./EmptyState";

export const CommunityInbox = () => (
  <Switch>
    <Route
      exact
      path="/settings/community-inbox/conversations/:id"
      component={BotChatRoom}
    />
    <Route path="/" component={EmptyState} />
  </Switch>
);
