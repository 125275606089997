import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface FeatureCardProps {
  color: "purple" | "green" | "pink";
  icon: IconType;
  feature: "earn_rewards" | "collect_points" | "level_up";
  iconSize?: number;
  featureCount?: number;
}

const bgColormap = {
  purple: "bg-[#ECE2FF]",
  green: "bg-[#EBFAF5]",
  pink: "bg-[#FFF1F3]",
};

const darkIconColorMap = {
  purple: "text-[#AA91DD]",
  green: "text-[#B0EBD9]",
  pink: "text-[#FFC9D3]",
};

const darkIconBGColorMap = {
  purple: "bg-[#AA91DD]",
  green: "bg-[#B0EBD9]",
  pink: "bg-[#FFC9D3]",
};

const featureKeys = ["feature_1", "feature_2", "feature_3", "feature_4"];

export const FeatureCard = ({
  color,
  feature,
  icon,
  iconSize = 20,
  featureCount = 3,
}: FeatureCardProps) => {
  const translationRoot = `leaderboard.key_features.${feature}`;
  return (
    <div
      className={classNames(
        "col-span-6 h-[500px] rounded-[10px] p-12 xl:col-span-5 xl:pb-4",
        bgColormap[color],
      )}
    >
      <div
        className={classNames(
          "flex h-12 w-12 items-center justify-center rounded-full",
          darkIconBGColorMap[color],
        )}
      >
        <Icon type={icon} size={iconSize} />
      </div>
      <div className="mt-5">
        <Typography.TitleLg color="text-[#2B2E33]">
          {t(`${translationRoot}.title`)}
        </Typography.TitleLg>
      </div>
      <div className="mt-5">
        <Typography.BodyMd color="text-[#2B2E33]">
          {t(`${translationRoot}.description`)}
        </Typography.BodyMd>
      </div>
      <div className="mt-5 flex flex-col space-y-4">
        {featureKeys.slice(0, featureCount).map(feature => (
          <div key={feature} className="flex items-start space-x-3">
            <div className="h-5 w-5">
              <Icon
                type="20-checkmark-circle-fill-dark"
                size={20}
                className={darkIconColorMap[color]}
              />
            </div>
            <Typography.BodyMd color="text-[#2B2E33]">
              {t(`${translationRoot}.${feature}`)}
            </Typography.BodyMd>
          </div>
        ))}
      </div>
    </div>
  );
};
