import { isResultComment } from "@/react/components/HeaderV2/Search/SearchResult/searchResultHelpers";
import type { AdvancedSearchResult } from "@/react/components/search/types";
import { SearchResultItem } from "../SearchResultItem";

interface Props {
  item: AdvancedSearchResult;
}
// this is just a compiled of all the different search result items, will be replaced when we have filters
export const SearchResult = ({ item }: Props) => {
  const { type } = item || {};

  const componentsMap: any = {
    post: SearchResultItem.Post,
    community_member: SearchResultItem.Member,
    comment: isResultComment(item) && SearchResultItem.Comment,
    lesson: SearchResultItem.Lesson,
    event: SearchResultItem.Event,
    image: SearchResultItem.ImagePost,
  };

  const Component = componentsMap[type];

  if (!Component) {
    return null;
  }

  return <Component item={item} />;
};
