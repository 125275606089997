import { t } from "@/i18n-js/instance";
import { useProfileFieldDetail } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useProfileFieldDetail";
import { BaseResource } from "../../BaseResource";

export const ProfileFieldResource = ({
  resourceId,
}: {
  resourceId: number;
}) => {
  const {
    getOptions: { data: profileField = {}, isLoading },
  } = useProfileFieldDetail({ fieldId: resourceId });

  return (
    <BaseResource
      label={t("settings.workflows.view.resources.profile_field")}
      value={profileField.label}
      isLoading={isLoading}
    />
  );
};
