import { useMutation } from "react-query";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

export const useCreateBotChat = () => {
  const { mutateAsync: createChat } = useMutation(
    (botValues: any) =>
      reactQueryPost(
        internalApi.communityInbox.create({
          params: { community_bot_profile_id: botValues.id },
        }),
      ),
    {
      onError: err => {
        console.error("Error while creating chat", err);
      },
    },
  );

  return {
    createChat,
  };
};
