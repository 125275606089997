import { useState } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { PageMetaData } from "@/react/components/Layout/PageMetaData";
import { useActiveView } from "@/react/hooks/utils/useActiveView";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { CommunityMembers, Filters } from "@circle-react-shared/MembersList";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { useNavigationLinks } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavigationLinks";
import { StandardLayout } from "../Layout/StandardLayout";
import { ViewTypes } from "../shared/MembersList/CommunityMembers/ListView/CommunityMember/MemberActions";
import { Header } from "./Header";
import type { MemberDirectorySettings } from "./useMemberDirectorySettings";

const bodyClassNames = "view-members-directory";

export interface MemberDirectoryContentProps {
  memberDirectorySettings: MemberDirectorySettings;
}

export const MemberDirectoryContent = ({
  memberDirectorySettings,
}: MemberDirectoryContentProps) => {
  const { isV3Enabled } = useIsV3();
  const { memberDirectoryLink } = useNavigationLinks();
  const [memberCount, setMemberCount] = useState(0);

  const [hasMobileFiltersTransition, setHasMobileFiltersTransition] =
    useState(false);

  const [activeView, setActiveView]: any[] = useActiveView({
    defaultCommunityView: memberDirectorySettings.view,
    views: VIEWS,
  });

  const memberDirectoryTitle = isV3Enabled
    ? memberDirectoryLink?.label || t("members_directory.title")
    : t("members_directory.title");

  return (
    <>
      <Header
        memberCount={memberCount}
        activeView={activeView}
        setActiveView={setActiveView}
        setShouldRenderMobileFiltersTransition={setHasMobileFiltersTransition}
        headerTitle={memberDirectoryTitle}
      />
      <StandardLayout.Body hasPaddingTopMobile={false}>
        <PageMetaData
          title={memberDirectoryTitle}
          bodyClassNames={bodyClassNames}
        />

        <StandardLayout.Content className={classNames("md:!min-w-0")}>
          <Filters
            shouldRenderMobileFiltersTransition={hasMobileFiltersTransition}
            shouldBeHiddenOnMd
            shouldShowAdminFilters
          />
          <CommunityMembers
            setMemberCount={setMemberCount}
            scopeResults={memberDirectorySettings.scope}
            defaultSort={memberDirectorySettings.defaultSort}
            shouldShowHiddenMemberTooltip
            viewType={ViewTypes.MEMBER_DIRECTORY}
            activeView={activeView}
          />
        </StandardLayout.Content>
      </StandardLayout.Body>
    </>
  );
};
